import { inject, Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { getAppFeaturesFromStripeEntitlements, RcFeature } from 'rc-common';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Mutation, MutationUpdateProfileArgs, Query, User } from '../generated';
import { RcApiService } from './rc-api.service';

@Injectable()
export class UserService {
  private readonly rcApiService = inject(RcApiService);

  public updateProfile(args: MutationUpdateProfileArgs): Observable<User> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation UpdateProfile($name: String!) {
            updateProfile(name: $name) {
              id
              name
              email
              organizations {
                isAdmin
                organization {
                  id
                  name
                }
              }
              createdAt
              updatedAt
              subscription {
                id
                stripeCustomerId
                stripeSubscriptionId
                stripeEntitlements
              }
            }
          }
        `,
        variables: args,
      })
      .pipe(map((result) => result!.data!.updateProfile));
  }

  public getProfile(
    args: Omit<
      Parameters<typeof this.rcApiService.apollo.query<Query>>[0],
      'query' | 'variables'
    > = {},
  ): Observable<User> {
    return this.rcApiService.apollo
      .query<Query>({
        query: gql`
          query GetProfile {
            getProfile {
              id
              name
              email
              organizations {
                isAdmin
                organization {
                  id
                  name
                }
              }
              createdAt
              updatedAt
              subscription {
                id
                stripeCustomerId
                stripeSubscriptionId
                stripeEntitlements
              }
            }
          }
        `,
        ...args,
      })
      .pipe(map((result) => result.data.getProfile));
  }

  public createBillingSession(): Observable<string> {
    return this.rcApiService.http
      .post<{
        url: string;
      }>(
        `${environment.apiUrl}/subscriptions/create-billing-portal-session`,
        {},
      )
      .pipe(map((result) => result.url));
  }

  public createCheckoutSession(args: {
    priceId: string;
  }): Observable<{ id: string; url: string }> {
    return this.rcApiService.http.post<{
      id: string;
      url: string;
    }>(`${environment.apiUrl}/subscriptions/create-checkout-session`, args);
  }

  public getStripeSubscription(): Observable<any> {
    return this.rcApiService.http.get(
      `${environment.apiUrl}/subscriptions/stripe-subscription`,
    );
  }

  public hasFeatures(
    ...features: [RcFeature, ...RcFeature[]]
  ): Observable<boolean> {
    return this.getProfile().pipe(
      map((user) => {
        const entitedFeatures = getAppFeaturesFromStripeEntitlements(
          ...(user.subscription?.stripeEntitlements || []),
        );

        return features.every((feature) => entitedFeatures.includes(feature));
      }),
    );
  }
}
